import { Seo } from '~/cms/front/core/seo/Seo'

class ArticleData extends Seo {
	get author () {
		const author = this.app.page.value.author
		return {
			person: () => ({
				'@type': 'Person',
				name: `${author.data.name} ${author.data.surname}`,
				url: `${this.app.settings.env.origin}/api/images${author.data.avatar.path}`
			}),
			organization: () => ({
				'@type': 'Organization',
				name: author.data.name,
				logo: {
					'@type': 'ImageObject',
					url: `${this.app.settings.env.origin}/api/images${author.data.logo.path}`
				}
			})
		}[author.type]()
	}

	get schemaJSON () {
		return {
			'@context': 'https://schema.org/',
			'@type': 'Article',
			headline: this.app.page.value.title,
			datePublished: this.app.page.value.datePublished,
			image: `${this.app.settings.env.origin}/api/images/${this.app.page.value.thumbnail.path}`,
			author: this.author,
			publisher: {
				'@type': 'Organization',
				name: this.app.settings.general.contact.pageName,
				logo: {
					'@type': 'ImageObject',
					url: `${this.app.settings.env.origin}/api/images/nimphy_logo.png`,
					width: 210,
					height: 60
				}
			}
		}
	}
}

export { ArticleData }
