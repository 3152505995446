<template>
	<article class="article">
		<base-container>
			<base-row>
				<base-col col="12" lg="8" offset-lg="2">
					<div class="article-body" ref="sectionContent">
						<base-font class="blog-header" variant="section-header">{{article.title}}</base-font>
						<base-font class="article-date" color="primary">{{article.datePublished | date}}</base-font>
						<div class="article-introduction" v-html="article.introduction"></div>
						<cms-multi-section class="article-text" value="content" />
					</div>
				</base-col>
				<base-col col="12" md="5" xl="4" xxl="3">
					<base-button class="back-button" to="article-list" type="white">{{langs.back_button}}</base-button>
				</base-col>
			</base-row>
		</base-container>
	</article>
</template>

<script>
import CmsMultiSection from '~/cms/front/components/CmsMultiSection'
import { pageMixin } from '~/website/front/core/pages/page.mixin'

export default {
	props: {
		prefix: String
	},
	components: {
		CmsMultiSection
	},
	data: () => ({
		progress: 0
	}),
	computed: {
		article () {
			return this.$app.page.value
		},
		langs () {
			return this.$app.translator.get('articles')
		}
	},
	async prefetch () {
		await this.$app.translator.prefetch('articles')
	},
	mixins: [
		pageMixin
	]
}
</script>
<style lang="scss" scoped>
.article{
	.back-button {
		justify-content: center;
	}
}
.article-body {
	:deep(.blog-header)  {
		margin-bottom: 1rem;
	}
	.article-introduction {
		font-size: 1.6rem;
		color: $primary;
		text-align: justify;
		font-style: italic;
		margin-bottom: 2rem;

		@include media-breakpoint-up(xl) {
			font-size: 1.8rem;
			margin-bottom: 3rem;
		}
	}

	:deep(.article-date)  {
		width: 100%;
		position: relative;
		overflow: hidden;
		opacity: .5;

		&::after {
			content: '';
			position: absolute;
			top: 50%;
			left: 10rem;
			width: 100%;
			border-bottom: .1rem solid $gray-800;
		}
	}
}
</style>
